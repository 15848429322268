import React, { createContext, useContext } from "react";
import useAsync from "../../hooks/useAsync";
import RateServices from "../../services/RateServices";

const RateContext = createContext();

const RateProvider = ({ children }) => {
  const { data, loading, refetchData } = useAsync(RateServices.getRates);

  return (
    <RateContext.Provider value={{ data: data.data, loading, refetchData }}>
      {children}
    </RateContext.Provider>
  );
};

export function useRateContext() {
  const context = useContext(RateContext);

  //   if (context === undefined) {
  //     throw new Error("useRateContext should be used within a RateProvider");
  //   }

  const { data, loading, refetchData } = context ?? {};

  return { data, loading, refetchData };
}
export default RateProvider;
