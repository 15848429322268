import React, { createContext, useContext } from "react";
import useAsync from "../../hooks/useAsync";
import SizeServices from "../../services/SizeServices";

const SizeContext = createContext();

const SizeProvider = ({ children }) => {
  const { data, loading, refetchData } = useAsync(SizeServices.getSizes);

  return (
    <SizeContext.Provider value={{ data: data?.data, loading, refetchData }}>
      {children}
    </SizeContext.Provider>
  );
};

export function useSizeContext() {
  const context = useContext(SizeContext);

  const { data, loading, refetchData } = context ?? {};

  return { data, loading, refetchData };
}
export default SizeProvider;
