import requests from "./httpService";

const AdminServices = {
  getBayAdmins() {
    return requests.get("/auth/admins/bay/");
  },
  addBayAdmin(body) {
    return requests.post("/auth/admins/bay/", body);
  },

  getShippingAdmins() {
    return requests.get("/auth/admins/shipping/");
  },
  addShippingAdmin(body) {
    return requests.post("/auth/admins/shipping/", body);
  },

  loginAdmin(body) {
    return requests.post(`/auth/login/`, body);
  },
  createAccount(body) {
    return requests.post(`/auth/users/`, body);
  },

  getAllUsers() {
    return requests.get("/auth/users/");
  },

  deleteUser(id, body) {
    return requests.delete(`/auth/users/${id}`, body);
  },

  suspendUser(id) {
    return requests.get(`/auth/users/${id}/suspend`);
  },

  unsuspendUser(id) {
    return requests.get(`/auth/users/${id}/unsuspend`);
  },

  /**
   *
   * @param {*current_password, new_password } body
   * @returns
   */
  changePassword(body) {
    return requests.put("/auth/users/set_password/", body);
  },

  forgotPassword(body) {
    return requests.post("/auth/users/reset_password/", body);
  },

  /**
   *
   * @param {uid, token, new_password}
   * @returns
   */
  confirmPassword(body) {
    return requests.post("/auth/users/reset_password/confirm/", body);
  },
};

export default AdminServices;
