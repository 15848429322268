import requests from "./httpService";

const RateServices = {
  getRates() {
    return requests.get("/demurage/rates/");
  },

  addRate(body) {
    return requests.post("/demurage/rates/", body);
  },

  editRate(id, body) {
    return requests.put(`/demurage/rates/${id}`, body);
  },

  deleteRate(id) {
    return requests.delete(`/demurage/rates/${id}`);
  },
};

export default RateServices;
