import requests from "./httpService";

const SizeServices = {
  getSizes() {
    return requests.get("/demurage/sizes/");
  },

  getSizesByShippingCompany(company_id) {
    return requests.get(`/demurage/sizes/${company_id}`);
  },

  addSize(body) {
    return requests.post("/demurage/sizes/", body);
  },

  editSize(id, body) {
    return requests.put(`/demurage/sizes/${id}`, body);
  },

  deleteSze(id) {
    return requests.delete(`/demurage/sizes/${id}`);
  },
};

export default SizeServices;
