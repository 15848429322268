import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "./utils/toast";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoute from "./components/login/PrivateRoute";
import AdminProvider from "./context/Admin";
import ComposeProviders from "./utils/composeProviders";
import { useAuthContext } from "./context/AuthContext";
import { SidebarProvider } from "./context/SidebarContext";
import ShippingProvider from "./context/Shipping";
import BayProvider from "./context/Bay";
import BookingProvider from "./context/Booking";
import { getUserRole } from "./utils/roles";
import RateProvider from "./context/Rate";
import SizeProvider from "./context/Sizes";
import DemurrageProvider from "./context/Demurrage";

const Layout = lazy(() => import("./layout/Layout.js"));
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const ForgetPassword = lazy(() => import("./pages/ForgotPassword.js"));
const ConfirmPassword = lazy(() => import("./pages/ConfirmPassword.js"));

const App = () => {
  const {
    state: { adminInfo },
  } = useAuthContext();

  const userType = getUserRole(adminInfo);

  const components = [AdminProvider, BookingProvider, SidebarProvider];

  if (userType === "IS_SP_ADMIN") {
    components.push(ShippingProvider);
    components.push(RateProvider);
    components.push(DemurrageProvider);
    components.push(SizeProvider);
  }

  (userType === "IS_SP_ADMIN" || userType === "IS_SHIPPING_ADMIN") &&
    components.push(BayProvider);

  return (
    <ComposeProviders components={components}>
      <ToastContainer />
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgot-password" component={ForgetPassword} />
          <Route path="/confirm-password/:token" component={ConfirmPassword} />

          <PrivateRoute>
            <Route path="/" component={Layout} />
          </PrivateRoute>
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </ComposeProviders>
  );
};

export default App;
