import React, { createContext, useContext } from "react";
import useAsync from "../../hooks/useAsync";
import BookingServices from "../../services/BookingServices";
import DemurrageServices from "../../services/DemurrageServices";

const BookingContext = createContext();

const BookingProvider = ({ children }) => {
  const { data, loading, refetchData } = useAsync(
    BookingServices.getAllBookings
  );

  const {
    data: sizes,
    loading: isSizesLoading,
    refetchData: refetchSizes,
  } = useAsync(DemurrageServices.getDemurageSizes);

  return (
    <BookingContext.Provider
      value={{
        data: data.data,
        sizes: sizes.data,
        isSizesLoading: isSizesLoading,
        loading,
        refetchData,
        refetchSizes,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export function useBookingContext() {
  const context = useContext(BookingContext);

  if (context === undefined) {
    throw new Error(
      "useBookingContext should be used within a BookingProvider"
    );
  }

  return context;
}
export default BookingProvider;
