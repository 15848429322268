import requests from "./httpService";

const DemurrageServices = {
  getDemurageSizes() {
    return requests.get("/demurage/sizes/");
  },

  getDemurageSizesByShippingCompany(shippingLineId) {
    return requests.get(`/demurage/sizes?shipping_company=${shippingLineId}`);
  },
  createDemurageSize(body) {
    return requests.post("/demurage/sizes/", body);
  },
  getDemurageRates() {
    return requests.get("/demurage/rates/");
  },
  createDemurageRate(body) {
    return requests.post("/demurage/rates/", body);
  },
  getDemurageCalculations() {
    return requests.get("/demurage/calculations/");
  },
  getSingleDemurageCalculations(id) {
    return requests.get(`/demurage/calculations/${id}`);
  },
};

export default DemurrageServices;
