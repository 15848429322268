import React, { createContext, useContext } from "react";
import useAsync from "../../hooks/useAsync";
import DemurrageServices from "../../services/DemurrageServices";

const DemurrageContext = createContext();

const DemurrageProvider = ({ children }) => {
  const { data, loading, refetchData } = useAsync(
    DemurrageServices.getDemurageCalculations
  );

  return (
    <DemurrageContext.Provider value={{ data: data, loading, refetchData }}>
      {children}
    </DemurrageContext.Provider>
  );
};

export function useDemurrageContext() {
  const context = useContext(DemurrageContext);

  //   if (context === undefined) {
  //     throw new Error("useDemurrageContext should be used within a DemurrageProvider");
  //   }

  const { data, loading, refetchData } = context ?? {};

  return { data, loading, refetchData };
}
export default DemurrageProvider;
